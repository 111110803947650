import React from "react"
import Footer from "./Footer"
import NavBar from "./NavBar"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import Header from "./Header"
// import big from "./bgmain.jpg"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"

// const Espacio1 = styled.section`
//   /* position: relative; */
//   position: absolute;
//   left: 0px;
//   right: 0px;
//   top: 0px;
//   height: 60px;

//   margin-top: 0px;
//   padding-bottom: 30px;
// `

const Principal = styled.div`
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: scroll;
  /* left: 0px;
  right: 0px;
  top: 0px; */
  /* background: yellow; */
`
const Espacio1 = styled.div`
  position: relative;
  /* left: 0px;
  right: 0px;
  top: 0px;
  height: 33%;
  background: #628a7a; */
`

const Espacio2 = styled.div`
  position: relative;
  /* margin-bottom: 20px;
  margin-top: 20px;
  padding-top: 40%;
  height: 33%; */
`
const Espacio3 = styled.div`
  /* background: black;
  color: white; */
  position: relative;
  /* left: 0px;
  right: 0px;
  bottom: 0px; */
`

const Layout = ({ children, sbiggo, spacer, percentage }) => {
  return (
    <Principal>
      <Espacio1>
        <Header biggo={sbiggo} spacer={spacer} percentage={percentage} />
      </Espacio1>
      <Espacio2> {children}</Espacio2>

      <Espacio3>
        <Footer />
      </Espacio3>
    </Principal>
  )
}

export default Layout

// }

//  return (
//    <>
//      <Espacio1>
//        <Header biggo={sbiggo} />
//      </Espacio1>
//      <Espacio2> {children}</Espacio2>

//      <section>
//        <Footer />
//      </section>
//    </>
//  )
