import React from "react"
import styled from "styled-components"
import NavBar from "./NavBar"
import big from "./bgmain.jpg"

const Cabeza = styled.div`
  /* background-image: url(${big}); */
  background-image: url(${props => props.biggo});
  position: relative;
  /* position: absolute; */
  left: 0px;
  right: 0px;
  top: 0px;
  background-repeat: no-repeat;
  /* background-size: 100% 150%; */
  background-size: 100% ${props => props.percentage}%;
  height: fit-content;
  @media (max-width: 768px) {
    background-size: cover;
  }
`
const Spacer = styled.div`
  /* padding-bottom: 35%; */
  padding-bottom: ${props => props.spacer}%;
  @media (max-width: 768px) {
    padding-bottom: 15%;
  }
`

const Header = props => {
  return (
    <Cabeza biggo={props.biggo} percentage={props.percentage}>
      <NavBar />
      <Spacer spacer={props.spacer} />
    </Cabeza>
  )
}

export default Header

// const Cabeza = styled.div`
//   /* background-image: url(${big}); */
//   background-image: url(${props => props.biggo});

//   position: absolute;
//   left: 0px;
//   right: 0px;
//   top: 0px;
//   background-repeat: no-repeat;
//   background-size: 100% 150%;
//   /* background-size: cover; */
//   height: fit-content;
// `
