import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Col,
  Row,
  LinkContainer,
} from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

// const Marca = styled(Navbar.Brand)`
//   background: yellowgreen;
// `

const Box2 = styled.div`
  /* max-width: 100px; */
  /* font-size: 20px;
  font-weight: 300;
  font-family: "Titillium Web"; */
  padding-top: 10px;
  @media (max-width: 768px) {
    display: none;
  }

  img {
    width: 150px;
  }
`

const Box3 = styled.div`
  /* max-width: 100px; */
  /* font-size: 20px;
  font-weight: 300;
  font-family: "Titillium Web"; */

  display: none;
  padding-top: 10px;
  @media (max-width: 768px) {
    display: flexbox;
    padding-left: 25%;
    padding-bottom: 20px;
  }

  img {
    width: 150px;
  }
`

const NavBar = () => {
  return (
    <>
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <Box2>
              <Link to="/">
                <StaticImage
                  src="../assets/images/yakulogo.png"
                  alt="logo"
                  width="172"
                />
              </Link>
            </Box2>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            {/* <Box3>
              <Link to="/">
                <StaticImage src="../assets/images/yakulogo.png" alt="logo" />
              </Link>
            </Box3> */}
            <Navbar expand="lg">
              <Container>
                {/* <Marca href="#home">React-Bootstrap</Marca> */}

                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                <Navbar.Brand className="d-lg-none">
                  <Link to="/">
                    <StaticImage
                      src="../assets/images/yakulogo.png"
                      alt="logo"
                      width="120"
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav
                    className="justify-content-end"
                    style={{ width: "100%" }}
                  >
                    {/* <Nav.Link href="#home">Home</Nav.Link> */}
                    <Link to="/" className="nav-link" activeClassName="active">
                      Inicio
                    </Link>
                    <Link to="/QuienesSomos" className="nav-link">
                      Quiénes Somos
                    </Link>

                    <NavDropdown title="Servicios" id="basic-nav-dropdown">
                      <NavDropdown.Item as="li">
                        <Link to="/SitiosWeb" className="nav-link">
                          Sitios Web
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as="li">
                        <Link to="/AplicacionesWeb" className="nav-link">
                          Aplicaciones Web
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as="li">
                        <Link to="/Reinge" className="nav-link">
                          Re -ingenieria Web
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as="li">
                        <Link to="/Seguridad" className="nav-link">
                          Seguridad Web
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as="li">
                        <Link to="/Moviles" className="nav-link">
                          Aplicaciones Móviles
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as="li">
                        <Link to="/Marketing" className="nav-link">
                          Marketing Digital
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Link to="/Preguntas" className="nav-link">
                      Preguntas
                    </Link>
                    <Link to="/Contacto" className="nav-link">
                      Contacto
                    </Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
        </Row>
      </Container>

      {/* <ul>
        <li>
          <Link to="/">Inicio</Link>
        </li>
        <li>
          <Link to="/QuienesSomos">Quienes Somos</Link>
        </li>
        <li>
          <Link to="/Servicios">Servicios</Link>
        </li>
        <li>
          <Link to="/Contacto">Contacto</Link>
        </li>
      </ul> */}
    </>
  )
}

export default NavBar
