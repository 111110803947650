import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"

const Fondo = styled.div`
  background: black;
  color: white;
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  /* margin-top: 800px; */
`

const Box3 = styled.div`
  /* max-width: 100px; */
  /* font-size: 20px;
  font-weight: 300;
  font-family: "Titillium Web"; */
  padding-top: 70px;
  padding-bottom: 50px;

  img {
    width: 100px;
  }
`

const LinkBox = styled.ul`
  font-size: 100%;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  list-style-type: none;
  color: #8fccb5;
  padding: 0;
  li {
    padding-bottom: 15px;
  }

  a {
    text-decoration: none;
    color: #8fccb5;
  }
`
const Fondocopy = styled.div`
  padding-top: 40px;
  padding-bottom: 20px;
  color: #8fccb5;
  font-size: 100%;
  font-weight: 500;
  font-family: "Titillium Web";

  a {
    text-decoration: none;
    color: #8fccb5;
  }
`

const Footer = () => {
  return (
    <Fondo>
      <Container>
        <Row>
          <Col>
            <Box3>
              <StaticImage
                src="../assets/images/yakulogow.png"
                alt="logo"
                width="120"
              />
            </Box3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4} className="d-lg-block">
            <LinkBox>
              <li>
                <Link to="/QuienesSomos">Acerca de Yaku</Link>
              </li>
              <li>
                <Link to="/Preguntas">Preguntas</Link>
              </li>
              <li>
                <Link to="/Contacto">Contacto</Link>
              </li>
            </LinkBox>
          </Col>
          <Col className="d-none d-lg-block">
            <LinkBox>
              {/* <li>
                <a href="mailto:info@yaku.com.ec">Red Twitter</a>
              </li> */}
              <li>
                <a href="https://www.linkedin.com/company/yaku-soluciones-tecnologia-ecuador/">
                  Red Linkedin
                </a>
              </li>
              <li></li>
              <li></li>
              <li></li>
              <li>
                Suscríbase a nuestro boletín: <br />
                <a href="mailto:info@yaku.com.ec">boletin@yaku.com.ec</a>
              </li>
            </LinkBox>
          </Col>
          <Col className="d-none d-lg-block">
            <LinkBox>
              <li>
                <a href="mailto:info@yaku.com.ec">info@yaku.com.ec</a>
              </li>
              <li>
                <a href="tel:+593999944405">593 999944405</a>
              </li>
              <li>
                <Link to="/Politica">Política de Privacidad</Link>
              </li>
            </LinkBox>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fondocopy>
              Copyright © Yaku Tecnología 2021 |{" "}
              <a href="tel:+593999944405">tel: 593 999944405</a> | Sitio
              desarrollado con Gatsby.js
            </Fondocopy>
          </Col>
        </Row>
      </Container>
    </Fondo>
  )
}

export default Footer
